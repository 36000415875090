import { Enums } from "../types/database";
import { Point } from "../app/hooks/usePoints";
import { Event, EventEnum, EventTag } from "../app/hooks/useEvents";
import { LeaderboardKey } from "../app/hooks/useLeaderboardEvents";
import { PiPlusMinusFill } from "react-icons/pi";
import {
  FaHandHoldingMedical,
  FaPersonRunning,
  FaRocket,
  FaExplosion,
  FaBomb,
  FaClipboardCheck,
  FaHandFist,
  FaStar,
} from "react-icons/fa6";
import {
  valueToGrayRedGradient,
  valueToGreenGradient,
  valueToRedGradient,
  valueToRedGreenGradient,
} from "./colors";
import {
  IconBlock,
  IconDrop,
  IconEdge,
  IconEnumeratedSquare,
  IconFrisbee,
  IconHuck,
  IconOffenseDefenseSquare,
  IconPickup,
  IconPossession,
  IconScore,
  IconStatModifierKey,
  IconStatModifierLocation,
  IconZone,
} from "../components/icons";
import { MdDangerous } from "react-icons/md";
import { isEqual } from "lodash";
import { IconType } from "react-icons";
import { TbArrowBigUpLinesFilled } from "react-icons/tb";
import { PiArrowFatLinesUpFill as IconContinuation } from "react-icons/pi";
import { timestampBetween } from "./timeHelpers";
import { FormatType } from "./formatHelpers";
import { possessionIndexIsOffense } from "./pointsHelpers";
import { ReactElement } from "react";

export const eventsWithPredicate = ({
  events,
  predicate,
}: {
  events: Event[];
  predicate: (event: Event) => boolean;
}): Event[] => {
  return events?.filter((e) => predicate(e)) ?? [];
};

export const eventsInPoint = ({
  events,
  point,
}: {
  events: Event[];
  point: Point;
}): Event[] =>
  eventsWithPredicate({
    events,
    predicate: (e: Event) => e?.point_id === point?.id,
  });

export const eventsInPointWithType = ({
  events,
  point,
  eventType,
}: {
  events: Event[];
  point: Point;
  eventType: Enums<"event_type">;
}): Event[] =>
  eventsWithPredicate({
    events,
    predicate: (e: Event) =>
      e?.point_id === point?.id && e?.event === eventType,
  });

export const playerIdsInPoint = ({
  events,
  point,
}: {
  events: Event[];
  point: Point;
}): number[] => {
  const playerIds = eventsInPointWithType({
    events,
    point,
    eventType: "in",
  }).map((e) => e.player_id);
  // use a Set to ensure uniqueness, then return array
  return Array.from(new Set(playerIds));
};

type EventDetailsType = {
  label: string;
  labelPlural: string;
  labelShort: string;
  icon: IconType;
  iconOverlay?: IconStatModifierKey;
  iconOverlayLocation?: IconStatModifierLocation;
  variant: string;
  format?: FormatType;
  tagsUser?: EventTag[];
  tagsSystem?: EventTag[];
  gradientFn?: ({
    value,
    min,
    max,
  }: {
    value: number;
    min?: number;
    max?: number;
  }) => string;
};

export const EventBaseLookup: {
  [event_type in EventEnum]?: EventDetailsType;
} = {
  score: {
    label: "Score",
    labelPlural: "Scores",
    labelShort: "Score",
    icon: IconScore,
    variant: "primary",
    tagsUser: ["pickup", "callahan"],
    tagsSystem: ["endzone", "zone", "huck"],
    gradientFn: valueToGreenGradient,
  },
  assist: {
    label: "Assist",
    labelPlural: "Assists",
    labelShort: "Assist",
    icon: FaHandHoldingMedical,
    variant: "primary",
    tagsUser: ["pickup"],
    tagsSystem: ["endzone", "zone", "huck"],
    gradientFn: valueToGreenGradient,
  },
  huck_target: {
    label: "Huck Target",
    labelPlural: "Huck Targets",
    labelShort: "Huck Tgt",
    icon: IconHuck,
    iconOverlay: "number",
    variant: "info",
    format: "percent",
    tagsUser: ["pickup", "punt"],
    tagsSystem: [
      "complete",
      "incomplete",
      "endzone",
      "forced",
      "unforced",
      "score",
      "drop",
      "zone",
    ],
    gradientFn: valueToGreenGradient,
  },
  block: {
    label: "Block",
    labelPlural: "Blocks",
    labelShort: "Block",
    icon: IconBlock,
    variant: "success",
    tagsUser: ["pointblock", "callahan", "huck"],
    tagsSystem: ["zone"],
    gradientFn: valueToGreenGradient,
  },
  drop: {
    label: "Drop",
    labelPlural: "Drops",
    labelShort: "Drop",
    icon: IconDrop,
    variant: "danger",
    tagsUser: ["pickup", "endzone", "zone"],
    tagsSystem: [],
    gradientFn: valueToGrayRedGradient,
  },
  throw: {
    label: "Throw",
    labelPlural: "Throws",
    labelShort: "Throw",
    icon: TbArrowBigUpLinesFilled,
    iconOverlay: "number",
    iconOverlayLocation: "bottom",
    variant: "secondary",
    tagsUser: ["pickup", "pointblock", "punt"],
    tagsSystem: [
      "complete",
      "drop",
      "endzone",
      "forced",
      "huck",
      "incomplete",
      "score",
      "unforced",
      "zone",
    ],
    gradientFn: valueToGreenGradient,
  },
  score_them: {
    label: "Score Against",
    labelPlural: "Scores Against",
    labelShort: "Score Them",
    icon: IconScore,
    variant: "danger",
    tagsUser: [],
    tagsSystem: [],
    gradientFn: valueToRedGradient,
  },
};

export const EventDerivedLookup: {
  [event_type in LeaderboardKey]?: EventDetailsType;
} = {
  points: {
    label: "Point",
    labelPlural: "Points",
    labelShort: "Points",
    icon: FaClipboardCheck,
    variant: "dark",
    gradientFn: valueToGreenGradient,
  },
  throw_huck_cmp: {
    label: "Huck, Completed",
    labelPlural: "Hucks, Completed",
    labelShort: "Huck Cmp",
    icon: FaRocket,
    iconOverlay: "check",
    iconOverlayLocation: "bottom",
    variant: "info",
    tagsUser: EventBaseLookup?.throw?.tagsUser ?? [],
    tagsSystem: EventBaseLookup?.throw?.tagsSystem ?? [],
    gradientFn: valueToGreenGradient,
  },
  reception_huck: {
    label: "Huck, Received",
    labelPlural: "Hucks, Received",
    labelShort: "Huck Rec",
    icon: FaPersonRunning,
    iconOverlay: "check",
    variant: "info",
    tagsUser: EventBaseLookup?.huck_target?.tagsUser ?? [],
    tagsSystem: EventBaseLookup?.huck_target?.tagsSystem ?? [],
    gradientFn: valueToGreenGradient,
  },
  reception: {
    label: "Reception",
    labelPlural: "Receptions",
    labelShort: "Recpt",
    icon: IconPossession,
    iconOverlay: "number",
    variant: "dark",
    gradientFn: valueToGreenGradient,
  },
  reception_yards: {
    label: "Reception, Est Yards",
    labelPlural: "Receptions, Est Yards",
    labelShort: "Recpt, Yds",
    icon: IconPossession,
    iconOverlay: "distance",
    variant: "dark",
    gradientFn: valueToGreenGradient,
  },
  reception_zone: {
    label: "Reception, Zone",
    labelPlural: "Receptions, Zone",
    labelShort: "Recpt, Zn",
    icon: IconPossession,
    iconOverlay: "number",
    variant: "dark",
    gradientFn: valueToGreenGradient,
  },
  reception_endzone: {
    label: "Reception, Endzone",
    labelPlural: "Receptions, Endzone",
    labelShort: "Recpt, End",
    icon: IconPossession,
    iconOverlay: "number",
    variant: "dark",
    gradientFn: valueToGreenGradient,
  },
  reception_pickup: {
    label: "Reception, Pickup",
    labelPlural: "Receptions, Pickup",
    labelShort: "Recpt, PkU",
    icon: IconPossession,
    iconOverlay: "number",
    variant: "dark",
    gradientFn: valueToGreenGradient,
  },
  throw_cmp: {
    label: "Completion",
    labelPlural: "Completions",
    labelShort: "Comp",
    icon: FaStar,
    iconOverlay: "check",
    variant: "info",
    format: "integer",
    gradientFn: valueToGreenGradient,
  },
  throw_pct: {
    label: "Throw %",
    labelPlural: "Throw %",
    labelShort: "Throw %",
    icon: IconFrisbee,
    iconOverlay: "percent",
    iconOverlayLocation: "bottom",
    variant: "info",
    format: "percent",
    gradientFn: ({ value, min, max }) =>
      valueToRedGreenGradient({ value, min, max, midpoint: 70 }),
  },
  throw_div: {
    label: "Throw ⅟",
    labelPlural: "Throw ⅟",
    labelShort: "Throw ⅟",
    icon: IconFrisbee,
    iconOverlay: "divide",
    iconOverlayLocation: "bottom",
    variant: "info",
    format: "percent",
    gradientFn: ({ value, min, max }) =>
      valueToRedGreenGradient({ value, min, max, midpoint: 70 }),
  },
  throw_downfield_pct: {
    label: "Cutter Target %",
    labelPlural: "Cutter Targets %",
    labelShort: "Cut Tgt %",
    icon: IconContinuation,
    iconOverlay: "percent",
    iconOverlayLocation: "bottom",
    variant: "info",
    format: "percent",
    gradientFn: valueToGreenGradient,
  },
  throw_downfield_div: {
    label: "Cutter Target ⅟",
    labelPlural: "Cutter Targets ⅟",
    labelShort: "Cut Tgt ⅟",
    icon: IconContinuation,
    iconOverlay: "divide",
    iconOverlayLocation: "bottom",
    variant: "info",
    format: "percent",
    gradientFn: valueToGreenGradient,
  },
  throw_yards: {
    label: "Throw, Est Yards",
    labelPlural: "Throws, Est Yards",
    labelShort: "Throw, Yds",
    icon: IconFrisbee,
    iconOverlay: "distance",
    iconOverlayLocation: "bottom",
    variant: "info",
    gradientFn: valueToGreenGradient,
  },
  throw_huck_pct: {
    label: "Huck %",
    labelPlural: "Huck %",
    labelShort: "Huck %",
    icon: IconHuck,
    iconOverlay: "percent",
    variant: "info",
    format: "percent",
    gradientFn: ({ value, min, max }) =>
      valueToRedGreenGradient({ value, min, max, midpoint: 50 }),
  },
  throw_huck_div: {
    label: "Huck ⅟",
    labelPlural: "Huck ⅟",
    labelShort: "Huck ⅟",
    icon: IconHuck,
    iconOverlay: "divide",
    variant: "info",
    format: "percent",
    gradientFn: ({ value, min, max }) =>
      valueToRedGreenGradient({ value, min, max, midpoint: 50 }),
  },
  callahan: {
    label: "Callahan",
    labelPlural: "Callahans",
    labelShort: "Callahan",
    icon: FaBomb,
    variant: "dark",
    tagsUser: EventBaseLookup?.block?.tagsUser ?? [],
    tagsSystem: EventBaseLookup?.block?.tagsSystem ?? [],
    gradientFn: valueToGreenGradient,
  },
  throw_pickup_att: {
    label: "Pickups",
    labelPlural: "Pickups",
    labelShort: "Pickups",
    icon: IconPickup,
    iconOverlay: "number",
    iconOverlayLocation: "top",
    variant: "info",
    gradientFn: valueToGreenGradient,
  },
  throw_pickup_div: {
    label: "Pickup ⅟",
    labelPlural: "Pickups ⅟",
    labelShort: "Pick ⅟",
    icon: IconPickup,
    iconOverlay: "divide",
    iconOverlayLocation: "top",
    variant: "info",
    format: "percent",
    gradientFn: ({ value, min, max }) =>
      valueToRedGreenGradient({ value, min, max, midpoint: 80 }),
  },
  throw_pickup_pct: {
    label: "Pickup %",
    labelPlural: "Pickups %",
    labelShort: "Pick %",
    icon: IconPickup,
    iconOverlay: "percent",
    iconOverlayLocation: "top",
    variant: "info",
    format: "percent",
    gradientFn: ({ value, min, max }) =>
      valueToRedGreenGradient({ value, min, max, midpoint: 80 }),
  },
  throw_pickup_forced: {
    label: "Pickup, Forced",
    labelPlural: "Pickups, Forced",
    labelShort: "Pick, Frc",
    icon: IconPickup,
    iconOverlay: "forced",
    iconOverlayLocation: "top",
    variant: "info",
    gradientFn: valueToRedGradient,
  },
  throw_pickup_unforced: {
    label: "Pickup, Unforced",
    labelPlural: "Pickups, Unforced",
    labelShort: "Pick, UnF",
    icon: IconPickup,
    iconOverlay: "unforced",
    iconOverlayLocation: "top",
    variant: "info",
    gradientFn: valueToRedGradient,
  },
  throw_endzone_div: {
    label: "Endzone ⅟",
    labelPlural: "Endzones ⅟",
    labelShort: "EndZ ⅟",
    icon: IconScore,
    iconOverlay: "divide",
    iconOverlayLocation: "top",
    variant: "info",
    gradientFn: ({ value, min, max }) =>
      valueToRedGreenGradient({ value, min, max, midpoint: 80 }),
  },
  throw_endzone_pct: {
    label: "Endzone %",
    labelPlural: "Endzones %",
    labelShort: "EndZ %",
    icon: IconScore,
    iconOverlay: "percent",
    iconOverlayLocation: "top",
    variant: "info",
    format: "percent",
    gradientFn: ({ value, min, max }) =>
      valueToRedGreenGradient({ value, min, max, midpoint: 80 }),
  },
  throw_endzone_forced: {
    label: "Endzone, Forced",
    labelPlural: "Endzones, Forced",
    labelShort: "EndZ, Frc",
    icon: IconScore,
    iconOverlay: "forced",
    iconOverlayLocation: "top",
    variant: "info",
    gradientFn: valueToRedGradient,
  },
  throw_endzone_unforced: {
    label: "Endzone, Unforced",
    labelPlural: "Endzones, Unforced",
    labelShort: "EndZ, UnF",
    icon: IconScore,
    iconOverlay: "unforced",
    iconOverlayLocation: "top",
    variant: "info",
    gradientFn: valueToRedGradient,
  },
  pointblock: {
    label: "Pointblock",
    labelPlural: "Pointblocks",
    labelShort: "Pointblk",
    icon: FaHandFist,
    variant: "dark",
    tagsUser: EventBaseLookup?.block?.tagsUser ?? [],
    tagsSystem: EventBaseLookup?.block?.tagsSystem ?? [],
    gradientFn: valueToGreenGradient,
  },
  throwaway: {
    label: "Throwaway",
    labelPlural: "Throwaways",
    labelShort: "ThwAwy",
    icon: FaExplosion,
    variant: "danger",
    tagsUser: EventBaseLookup?.throw?.tagsUser ?? [],
    tagsSystem: EventBaseLookup?.throw?.tagsSystem ?? [],
    gradientFn: valueToRedGradient,
  },
  turnover: {
    label: "Turnover",
    labelPlural: "Turnovers",
    labelShort: "Turn",
    icon: MdDangerous,
    variant: "danger",
    tagsUser: EventBaseLookup?.throw?.tagsUser ?? [],
    tagsSystem: EventBaseLookup?.throw?.tagsSystem ?? [],
    gradientFn: valueToRedGradient,
  },
  net: {
    label: "+/-",
    labelPlural: "+/-",
    labelShort: "+/-",
    icon: PiPlusMinusFill,
    variant: "dark",
    gradientFn: ({ value, min, max }) =>
      valueToRedGreenGradient({ value, min, max, midpoint: 0 }),
  },
  edge: {
    label: "Edge, Overall",
    labelPlural: "Edge, Overall",
    labelShort: "Edge",
    icon: IconEdge,
    variant: "primary",
    format: "float01",
    gradientFn: valueToGreenGradient,
  },
  edge_o: {
    label: "Edge, Offense",
    labelPlural: "Edge, Offense",
    labelShort: "Edge, O",
    icon: IconEdge,
    iconOverlay: "offense",
    variant: "primary",
    format: "float01",
    gradientFn: valueToGreenGradient,
  },
  edge_b: {
    label: "Edge, Defense",
    labelPlural: "Edge, Defense",
    labelShort: "Edge, D",
    icon: IconEdge,
    iconOverlay: "defense",
    variant: "primary",
    format: "float01",
    gradientFn: valueToGreenGradient,
  },
};

export const EventCompleteLookup = {
  ...EventBaseLookup,
  ...EventDerivedLookup,
};

export const eventIsPointblock = (event: Event) =>
  event?.event === "block" && event.tags.includes("pointblock");

export const eventIsCallahan = (event: Event) =>
  event?.event === "block" && event.tags.includes("callahan");

export const eventIsCallahanScore = (event: Event) =>
  event?.event === "score" && event.tags.includes("callahan");

export const eventIsThrowaway = (
  event: Event,
  options: { includeHucks?: boolean; includeAssists?: boolean } = {}
) => {
  const { includeHucks = false } = options;
  return (
    ["throw", ...(includeHucks ? ["huck"] : [])].includes(event?.event) &&
    event.tags.includes("incomplete")
  );
};

export const eventIsCompletedHuck = (event: Event) =>
  event?.event === "throw" &&
  (["complete", "huck"] as EventTag[]).every((e) => event.tags.includes(e));

export const eventIsHuckReceived = (event: Event) =>
  event?.event === "throw" &&
  event.tags.includes("complete") &&
  event.tags.includes("huck");

export const eventIsHuckForScore = (event: Event) =>
  eventIsCompletedHuck(event) && event.tags.includes("score");

export const eventIsHuckReceivedForScore = (event: Event) =>
  eventIsHuckReceived(event) && event.tags.includes("score");

export const eventIsCompletedThrow = (
  event: Event,
  options: { includeHucks?: boolean; includeAssists?: boolean } = {}
) => {
  const { includeHucks = false, includeAssists = false } = options;

  // ignore completed hucks for scores on assists as they will be double counted
  // both the huck & assist events will register as a completion
  if (
    event?.event === "assist" &&
    (["huck", "complete"] as EventTag[]).every((e) => event.tags.includes(e))
  )
    return false;

  return (
    [
      "throw",
      ...(includeHucks ? ["huck"] : []),
      ...(includeAssists ? ["assist"] : []),
    ].includes(event?.event) && !event.tags.includes("incomplete")
  );
};

export const eventIsPickup = (
  event: Event,
  options: { includeHucks?: boolean; includeAssists?: boolean } = {}
) => {
  const { includeHucks = false, includeAssists = false } = options;

  // ignore completed hucks for scores on assists as they will be double counted
  // both the huck & assist events include a pickup tag
  if (
    event?.event === "assist" &&
    (["pickup", "huck", "complete"] as EventTag[]).every((e) =>
      event.tags.includes(e)
    )
  )
    return false;

  return (
    [
      "throw",
      ...(includeHucks ? ["huck"] : []),
      ...(includeAssists ? ["assist"] : []),
    ].includes(event?.event) && event.tags.includes("pickup")
  );
};

export const findExistingThrow = (
  events: Event[],
  pointId: string,
  playerId: number,
  // event tags must match `tags`
  tags: EventTag[] = [],
  possession: number = undefined
): Event =>
  events.find(
    (event) =>
      event.point_id === pointId &&
      event.event === "throw" &&
      event?.player_id === playerId &&
      (possession === undefined || event?.possession === possession) &&
      isEqual(tags.sort(), (event?.tags ?? []).sort())
  );

export const eventToDetailedEvent = ({
  event,
}: {
  event: Event;
}): keyof typeof EventCompleteLookup => {
  if (eventIsPointblock(event)) return "pointblock";
  else if (eventIsCallahan(event)) return "callahan";
  else if (eventIsThrowaway(event)) return "throwaway";
  else if (eventIsCompletedHuck(event)) return "throw_huck_cmp";
  else if (eventIsHuckReceived(event)) return "reception_huck";

  return event?.event;
};

export const eventCreatedAtBetween = (
  eventBefore: Event,
  eventAfter: Event,
  secondsInterval: number = 20
) => {
  console.log(eventBefore);
  console.log(eventAfter);
  return timestampBetween(
    eventBefore?.created_at,
    eventAfter?.created_at,
    secondsInterval
  );
};

export const EventLabels = ({ tags }: { tags: EventTag[] }) => (
  <small className="opacity-50">
    {tags.includes("pickup") ? <IconPickup className="ms-1" /> : null}
    {tags.includes("zone") ? <IconZone className="ms-1" /> : null}
    {tags.includes("endzone") ? <IconScore className="ms-1" /> : null}
  </small>
);

export const EventPossessionIcons = ({
  point,
  event,
  variant,
}: {
  point: Point;
  event: Event;
  variant?: string;
}) => {
  const isOffense = possessionIndexIsOffense(point, event?.possession);
  return typeof event?.possession === "number" ? (
    <>
      <IconOffenseDefenseSquare
        offense={isOffense}
        filled={false}
        className={`${
          variant
            ? `text-${variant}`
            : isOffense
            ? "text-success"
            : "text-danger"
        }`}
      />
      <IconEnumeratedSquare
        count={Math.floor(event.possession / 2) + 1}
        className={`me-1 ${
          variant
            ? `text-${variant}`
            : isOffense
            ? "text-success"
            : "text-danger"
        }`}
        filled
      />
    </>
  ) : null;
};

// return last event in queue if it is a completed-throw
// confirms receiving player id if provided in `receiver_id`
export const precedingCompletionEvent = ({
  events,
  receiver_id,
}: {
  events: Event[];
  receiver_id?: number;
}) => {
  const lastEvent = events.length > 0 ? events[events.length - 1] : null;
  return lastEvent &&
    eventIsCompletedThrow(lastEvent, {
      includeHucks: false,
      includeAssists: false,
    }) &&
    (!receiver_id ||
      (lastEvent?.player_ids_related?.length === 1 &&
        lastEvent?.player_ids_related[0] === receiver_id))
    ? lastEvent
    : null;
};

export const EventIcon = ({
  icon,
  variant,
  color,
}: {
  icon: IconType;
  variant?: string;
  color?: string;
}): ReactElement => {
  const Icon = icon;
  return (
    <span
      className={`me-1 d-flex align-items-center ${
        variant ? `text-${variant}` : ""
      }`}>
      <Icon style={{ color }} />
    </span>
  );
};
