import { useMemo, useCallback, createContext } from "react";
import useSupabase from "./useSupabase";
import { Enums, Tables } from "../../types/database";
import useTable from "./useTable";
import { SupabaseClient } from "@supabase/supabase-js";

export type Team = Tables<"teams">;

type TeamsContextType<Team> = {
  team: Team;
  isLoading: boolean;
  updateTeam: (team: Team) => Promise<Team[]>;
  deleteTeam: (team: Team) => Promise<Team[]>;
  insertTeam: (team: Team) => Promise<Team[]>;
};

export const TeamsContext = createContext<TeamsContextType<Team>>({
  team: null,
  isLoading: false,
  updateTeam: null,
  deleteTeam: null,
  insertTeam: null,
});

export function useTeams({
  id,
  visible = true,
}: {
  id: number;
  visible?: boolean;
}): TeamsContextType<Team> {
  const client = useSupabase();
  const key = useMemo(() => ["teams", id], [id]);
  const idAltPredicate = useCallback(
    (team: Team) =>
      JSON.stringify(
        ["location", "name"].map(
          (
            key: keyof Team
          ): string | number | number[] | boolean | Enums<"ux_panel_type">[] =>
            team?.[key]
        )
      ),
    []
  );
  const {
    rows: teams,
    isLoading,
    updateRow: updateTeam,
    insertRows: insertTeam,
    deleteRows: deleteTeam,
  } = useTable<Team>({
    table: "teams",
    visible,
    key,
    filter: `id=eq.${id}`,
    idAltPredicate,
    selectFunction: async (): Promise<Team[]> =>
      await selectFunction({ client, id }),
    updateFunction: async (team: Team): Promise<Team[]> =>
      await updateFunction({ client, team }),
    insertFunction: async (team: Team): Promise<Team[]> =>
      await insertFunction({ client, team }),
    deleteFunction: async (team: Team): Promise<Team[]> =>
      await deleteFunction({ client, team }),
  });

  const team = useMemo(
    (): Team => (teams?.length > 0 ? teams[0] : null),
    [teams]
  );

  return {
    team,
    isLoading,
    updateTeam,
    insertTeam,
    deleteTeam,
  };
}

const selectFunction = async ({
  client,
  id,
}: {
  client: SupabaseClient;
  id: number;
}): Promise<Team[]> =>
  client
    .from("teams")
    .select()
    .eq("id", id)
    .order("name", { ascending: true })
    .throwOnError()
    .then((result): Team[] => result.data);

export const updateFunction = async ({
  client,
  team,
}: {
  client: SupabaseClient;
  team: Team;
}): Promise<Team[]> =>
  client
    .from("teams")
    .update(team)
    .eq("id", team.id)
    .throwOnError()
    .select()
    .throwOnError()
    .then((result): Team[] => result.data);

export const insertFunction = async ({
  client,
  team,
}: {
  client: SupabaseClient;
  team: Team;
}): Promise<Team[]> =>
  client
    .from("teams")
    .insert([team])
    .throwOnError()
    .select()
    .throwOnError()
    .then((result): Team[] => result.data);

export const deleteFunction = async ({
  client,
  team,
}: {
  client: SupabaseClient;
  team: Team;
}): Promise<Team[]> =>
  client
    .from("team")
    .delete()
    .eq("id", team.id)
    .throwOnError()
    .select()
    .throwOnError()
    .then((result): Team[] => result.data);
