export type FormatType = "percent" | "integer" | "float01" | "float02";

export const formatNumberToString = (
  value: number,
  format: FormatType
): string => {
  value = value ?? 0;
  switch (format) {
    case "percent":
      return `${(value * 100).toFixed(0)}%`;
    case "integer":
      return Math.round(value).toString();
    case "float01":
      return value.toFixed(1);
    case "float02":
      return value.toFixed(2);
    default:
      return value.toString();
  }
};
