export function timestampBetween(
  date_before: string,
  date_after: string,
  secondsInterval: number = 15
): Date {
  // if neither a `date_before` or `date_after` are provided, return `undefined`
  if (!date_before && !date_after) {
    return undefined;
  }
  // if no `date_after` is provided, (new) last element so return `secondsInterval` after `date_before`
  else if (!date_after) {
    return new Date(new Date(date_before).getTime() + secondsInterval * 1000);
  }
  // if no `date_before` is provided, (new) first element so return `secondsInterval` before `date_after`
  else if (!date_before) {
    return new Date(new Date(date_after).getTime() - secondsInterval * 1000);
  }

  // if both `date_before` and `date_after` are provided, provide a date in between them
  const beforeTime = new Date(date_before).getTime();
  const afterTime = new Date(date_after).getTime();
  return new Date((beforeTime + afterTime) / 2);
}
